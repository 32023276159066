import $ from 'jquery';
import ParsleyOptions from "../../components/ParsleyOptions";

export default class ForgotPasswordForm {

    private $form: JQuery<HTMLFormElement>;
    private $btn: JQuery<HTMLElement>;
    private $success: JQuery<HTMLElement>;
    private $error: JQuery<HTMLElement>;

    constructor(element: JQuery) {
        this.$form = (element as JQuery<HTMLFormElement> );

        if (this.$form.length) {
            this.$btn = this.$form.find('.btn-ajax');
            this.$success = this.$form.find('.alert-success');
            this.$error = this.$form.find('.alert-danger');

            this.$form.parsley(new ParsleyOptions());
            this.setEvents();
        }
    }

    private setEvents() {
        this.$form.submit(this.onSubmit);
    }

    private onSubmit = (event: JQuery.Event) => {
        event.preventDefault();
        this.$btn.ajaxButton("start");
        let Email = this.$form.find('#email').val();
        // ajax call
        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            contentType: 'application/json;charset=utf-8',
            data: JSON.stringify({ Email }),
            url: '/api/ForgotPassword',
            headers: { "RequestVerificationToken": (window as any)._requestVerificationToken }
        })
            .done(this.onDone)
            .fail(this.onFail)
            .always(() => {
                this.$form.find('.form-fields').remove();
                this.$btn.remove();
            })
        ;
        return false;
    };

    private onDone = (response: MoulaAjaxResponse) => {
        // We do not tell user if the email is exidt or not, because of privacy problem
        this.$success.show();
    };

    private onFail = () => {
        this.$error.show();
    }
}