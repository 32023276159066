import * as $ from "jquery";
import URLHelper from "../../../core/helpers/url";

export default class ResetPasswordForm {

    private $form: JQuery<HTMLFormElement>;
    private $btn: JQuery<HTMLElement>;
    private $success: JQuery<HTMLElement>;
    private $error: JQuery<HTMLElement>;

    constructor(element: JQuery) {
        this.$form = (element as JQuery<HTMLFormElement> );

        if (this.$form.length) {
            this.$btn = this.$form.find('.btn-ajax');
            this.$success = this.$form.find('.alert-success');
            this.$error = this.$form.find('.alert-danger');
            this.setEvents();
        }
    }

    private setEvents() {
        this.$form.submit(this.onSubmit);
    }

    private onSubmit = (event: JQuery.Event) => {
        event.preventDefault();
        this.$btn.ajaxButton("start");

        let UserID:string = URLHelper.getParameterByName('userId') as string;
        let code:string = URLHelper.getParameterByName('code') as string;
        let Password:string = this.$form.find('#password').val() as string;

        // ajax call
        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            contentType: 'application/json;charset=utf-8',
            data: JSON.stringify({ UserID, code, Password, ConfirmPassword: Password }),
            url: '/ResetPasswordApi',
            headers: { "RequestVerificationToken": (window as any)._requestVerificationToken }
        })
            .done(this.onDone)
            .fail(this.onFail)
            .always(() => {
                this.$btn.ajaxButton('stop');
            })
        ;
        return false;
    };

    private onDone = (response: MoulaAjaxResponse) => {
        if (response.IsSuccess) {
            this.$success.show();
            this.$form.find('.show-success').show();
            this.$form.find('.hide-success').hide();
        } else {
            this.$error.show();
            this.$error.html(`<strong>Something went wrong</strong><br/>${response.Message.replace(/\n/g, '<br/>')}`);
        }
    };

    private onFail = () => {
        this.$form.find('show-error').show();
        this.$form.find('hide-error').hide();
        this.$error.show();
    }
}