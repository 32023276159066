export default class ParsleyOptions {
    successClass: string;
    errorClass: string;
    classHandler: (el:any) => any;
    errorsWrapper: string;
    errorTemplate: string;

    constructor() {
        this.successClass = "has-success";
        this.errorClass = "has-danger";
        this.classHandler = function (el: any) {
            return el.$element.closest('.form-group');
        };
        this.errorsWrapper = "<div class=\"form-control-feedback\"></div>";
        this.errorTemplate = "<span></span>";
    }
}