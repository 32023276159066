import 'babel-polyfill'
import "../components";

import $ from "jquery";
import LoginForm from "./components/login-form.component";
import ForgotPasswordForm from "./components/forgot-password-form.component";
import ResetPasswordForm from "./components/reset-password-form.component";
import {PasswordValidator} from "../components/PasswordValidator/password_validator";

$(() => {
    const $loginForm: JQuery<HTMLElement> = $("#login-form");
    const $forgotForm: JQuery<HTMLElement> = $("#forgot-password-form");
    const $resetForm: JQuery<HTMLElement> = $("#reset-password-form");

    if ($loginForm.length) {
        const loginFormControl = new LoginForm($loginForm);
    }

    if ($forgotForm.length) {
        const forgotFormControl = new ForgotPasswordForm($forgotForm);
    }

    if ($resetForm.length) {
        const resetFormControl = new ResetPasswordForm($resetForm);
        $resetForm.find('.btn-ajax').ajaxButton('disable');
        $resetForm.on(PasswordValidator.PASSWORD_VALID,
            (e) => {
                $resetForm.find('.btn-ajax').ajaxButton('enable');
            });
        $resetForm.on(PasswordValidator.PASSWORD_INVALID,
            (e) => {
                $resetForm.find('.btn-ajax').ajaxButton('disable');
            });
    }
});